import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TextInputField,
  SelectField,
  Button,
  Pane,
  Heading,
  Strong,
} from 'evergreen-ui';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { addSingle, loginDropbox } from '../../apiClient';

const PrivacySelectOptions = [
  { id: 'private', name: 'Private' },
  { id: 'unlisted', name: 'Unlisted' },
  { id: 'public', name: 'Public' },
];

const GET_QUEUE_STATUS = gql`
  query getQueue {
    getQueue {
      info {
        inProgress
      }
    }
  }
`;

class ImportSingle extends Component {
  state = {
    payload: '',
    privacy: 'private',
    noAudio: false,
    loading: false,
    selfDeclaredMadeForKids: false,
  };

  componentDidMount() {
    const savedPayload = localStorage.getItem('singlePayload');
    if (savedPayload) {
      this.setState({ payload: savedPayload }, () => {
        this.addSingle();
      });
    }
  }

  savePayload = () => {
    const { payload } = this.state;
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('singlePayload', payload);
    }
  };

  removeSavedPayload = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('singlePayload');
      this.setState({ payload: '' });
    }
  };

  addSingle = async () => {
    const { payload, privacy, noAudio, selfDeclaredMadeForKids } = this.state;
    try {
      this.setState({ loading: true });
      await addSingle({
        payload,
        privacy,
        noAudio,
        selfDeclaredMadeForKids,
      });
      this.removeSavedPayload();
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { payload, noAudio, loading, selfDeclaredMadeForKids } = this.state;
    const { dropboxProfile } = this.props;
    return (
      <div>
        <Heading size={600} marginBottom={10}>
          Import a single video
        </Heading>
        <Pane display="flex">
          <Pane marginRight="20px" width="40%">
            <TextInputField
              label="Youtube video URL / Dropbox video name"
              onChange={e => this.setState({ payload: e.target.value })}
              value={payload}
            />
          </Pane>
          <Pane marginRight="20px" width="110px">
            <SelectField
              label="Privacy"
              clearable="false"
              searchable="false"
              onChange={e => this.setState({ privacy: e.target.value })}
            >
              {PrivacySelectOptions.map(o => (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              ))}
            </SelectField>
          </Pane>
          <Pane marginRight="20px">
            <Strong>No audio</Strong>
            <Checkbox
              onChange={e => this.setState({ noAudio: e.target.checked })}
              checked={noAudio}
            />
          </Pane>
          <Pane marginRight="20px">
            <Strong>For kids</Strong>
            <Checkbox
              onChange={e =>
                this.setState({ selfDeclaredMadeForKids: e.target.checked })
              }
              checked={selfDeclaredMadeForKids}
            />
          </Pane>
          <Pane>
            <div style={{ height: 24 }} />
            <Query query={GET_QUEUE_STATUS}>
              {({ data }) => (
                <Button
                  iconBefore="add"
                  appearance="primary"
                  isLoading={loading}
                  disabled={
                    data && data.getQueue && data.getQueue.info.inProgress
                  }
                  onClick={async () => {
                    if (!payload) {
                      return;
                    }
                    const source =
                      payload.indexOf('youtube') > -1 ? 'youtube' : 'dropbox';
                    if (
                      source === 'youtube' ||
                      (source === 'dropbox' && dropboxProfile)
                    ) {
                      this.addSingle();
                    } else {
                      this.savePayload();
                      loginDropbox();
                    }
                  }}
                >
                  Add
                </Button>
              )}
            </Query>
          </Pane>
        </Pane>
      </div>
    );
  }
}

ImportSingle.propTypes = {
  dropboxProfile: PropTypes.shape(),
};

const mapStateToProps = ({ user }) => ({
  dropboxProfile: user.dropboxProfile,
});

export default connect(mapStateToProps)(ImportSingle);
