import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TextInputField,
  SelectField,
  Button,
  Pane,
  Heading,
  Strong,
} from 'evergreen-ui';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { addBulk, loginDropbox } from '../../apiClient';

const PrivacySelectOptions = [
  { id: 'private', name: 'Private' },
  { id: 'unlisted', name: 'Unlisted' },
  { id: 'public', name: 'Public' },
];

const GET_QUEUE_STATUS = gql`
  query getQueue {
    getQueue {
      info {
        inProgress
      }
    }
  }
`;

// const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

class ImportBulk extends Component {
  state = {
    payload: '',
    privacy: 'private',
    noAudio: false,
    from: 1,
    to: 50,
    loading: false,
    selfDeclaredMadeForKids: false,
  };

  componentDidMount() {
    const savedPayload = localStorage.getItem('bulkPayload');
    if (savedPayload) {
      this.setState({ payload: savedPayload }, () => {
        this.addBulk();
      });
    }
  }

  savePayload = () => {
    const { payload } = this.state;
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('bulkPayload', payload);
    }
  };

  removeSavedPayload = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('bulkPayload');
      this.setState({ payload: '' });
    }
  };

  addBulk = async () => {
    const {
      payload,
      privacy,
      from,
      to,
      noAudio,
      selfDeclaredMadeForKids,
    } = this.state;
    try {
      this.setState({ loading: true });
      await addBulk({
        payload,
        privacy,
        from,
        to,
        noAudio,
        selfDeclaredMadeForKids,
      });
      this.removeSavedPayload();
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      payload,
      noAudio,
      from,
      to,
      loading,
      selfDeclaredMadeForKids,
    } = this.state;
    const { dropboxProfile } = this.props;
    return (
      <div>
        <Heading size={600} marginBottom={10}>
          Import videos in bulk
        </Heading>
        <Pane display="flex">
          <Pane marginRight="20px" width="40%">
            <TextInputField
              label="Youtube channel URL / Dropbox folder name"
              onChange={e => this.setState({ payload: e.target.value })}
              value={payload}
            />
          </Pane>
          <Pane marginRight="20px" width="110px">
            <SelectField
              label="Privacy"
              clearable="false"
              searchable="false"
              onChange={e => this.setState({ privacy: e.target.value })}
            >
              {PrivacySelectOptions.map(o => (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              ))}
            </SelectField>
          </Pane>
          <Pane marginRight="20px" width="60px">
            <TextInputField
              label="From"
              type="number"
              min="1"
              onChange={e => this.setState({ from: e.target.value })}
              value={from}
            />
          </Pane>
          <Pane marginRight="20px" width="60px">
            <TextInputField
              label="To"
              type="number"
              min="1"
              onChange={e => this.setState({ to: e.target.value })}
              value={to}
            />
          </Pane>
          <Pane marginRight="20px">
            <Strong>No audio</Strong>
            <Checkbox
              onChange={e => this.setState({ noAudio: e.target.checked })}
              checked={noAudio}
            />
          </Pane>
          <Pane marginRight="20px">
            <Strong>For kids</Strong>
            <Checkbox
              onChange={e =>
                this.setState({ selfDeclaredMadeForKids: e.target.checked })
              }
              checked={selfDeclaredMadeForKids}
            />
          </Pane>
          <Pane>
            <div style={{ height: 24 }} />
            <Query query={GET_QUEUE_STATUS}>
              {({ data }) => (
                <Button
                  iconBefore="add"
                  appearance="primary"
                  isLoading={loading}
                  disabled={
                    data && data.getQueue && data.getQueue.info.inProgress
                  }
                  onClick={async () => {
                    if (!payload) {
                      return;
                    }
                    const source =
                      payload.indexOf('youtube') > -1 ? 'youtube' : 'dropbox';
                    if (
                      source === 'youtube' ||
                      (source === 'dropbox' && dropboxProfile)
                    ) {
                      try {
                        this.addBulk();
                      } catch (err) {
                        console.log(err);
                      } finally {
                        this.setState({ loading: false });
                      }
                    } else {
                      this.savePayload();
                      loginDropbox();
                    }
                  }}
                >
                  Add
                </Button>
              )}
            </Query>
          </Pane>
        </Pane>
      </div>
    );
  }
}

ImportBulk.propTypes = {
  dropboxProfile: PropTypes.shape(),
};

const mapStateToProps = ({ user }) => ({
  dropboxProfile: user.dropboxProfile,
});

export default connect(mapStateToProps)(ImportBulk);
